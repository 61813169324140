<script>
    import { onMount, createEventDispatcher } from 'svelte';
    import Select from 'svelte-select';

    export let date = null; // Currently only accepts strings w/ format 'YYYY-MM-DD'
    export let minYear = 1930;
    export let maxYear = 2025;
    export let disabled = false;

    const dispatch = createEventDispatcher();

    let day, month, year;

    let dayOptions = [],
        monthOptions = [],
        yearOptions = [];

    const DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

    $: {
        if (day && month && year) {
            dispatch('change', {
                date: `${year.label}-${month.label}-${day.label}`,
            });
        }
    }

    onMount(() => {
        loadMonthOptions();
        loadDayOptions();
        loadYearOptions();

        if (date != null) {
            let splitDate = date.split('-');

            day = dayOptions.find((d) => d.label == splitDate[2]);
            month = monthOptions.find((m) => m.label == splitDate[1]);
            year = yearOptions.find((y) => y.label == splitDate[0]);
        }
    });

    function getNumMaxDays(month) {
        return DAYS[MONTHS.findIndex((m) => m == month)];
    }

    function loadDayOptions() {
        let numDays = month ? getNumMaxDays(month.value) : 31; // Default to 31

        dayOptions = [];
        for (let i = 1; i <= numDays; i++) {
            dayOptions.push({
                label: i <= 9 ? `0${i}` : i,
                value: i,
            });

            dayOptions = dayOptions;
        }
    }

    function loadMonthOptions() {
        monthOptions = MONTHS.map((m) => {
            return {
                label: m <= 9 ? `0${m}` : m,
                value: m,
            };
        });
    }

    function loadYearOptions() {
        yearOptions = [];
        for (let i = maxYear; i >= minYear; i--) {
            yearOptions.push({
                label: i,
                value: i,
            });

            yearOptions = yearOptions;
        }
    }
</script>

<div class="ds">
    <div class="ds__select">
        <Select
            items={dayOptions}
            listPlacement="bottom"
            bind:value={day}
            placeholder="DD"
            showIndicator={true}
            isDisabled={disabled}
            showChevron={true}
            isClearable={false}
        />
    </div>
    <div class="ds__select">
        <Select
            items={monthOptions}
            listPlacement="bottom"
            bind:value={month}
            on:select={(event) => {
                loadDayOptions();

                // The selected day of the month is not in range then
                // set it to the upper limit
                let numMaxDays = getNumMaxDays(month.value);
                if (day && day.value > numMaxDays) {
                    day = { label: numMaxDays, value: numMaxDays };
                }
            }}
            placeholder="MM"
            isDisabled={disabled}
            showIndicator={true}
            showChevron={true}
            isClearable={false}
        />
    </div>
    <div class="ds__select">
        <Select
            items={yearOptions}
            listPlacement="bottom"
            bind:value={year}
            placeholder="YYYY"
            isDisabled={disabled}
            showIndicator={true}
            showChevron={true}
            isClearable={false}
        />
    </div>
</div>

<style lang="scss" type="text/scss">.ds {
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  -moz-column-gap: 1.25em;
       column-gap: 1.25em;
  width: 100%;
}
.ds__select {
  width: 100%;
  font-size: 0.875em;
  font-weight: 400;
  --inputPadding: 15px 20px;
  --border: 1px solid #c7c7ca;
  --borderFocusColor: #662cc8;
  --borderRadius: 8px;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #662cc8;
  --multiItemActiveBG: #662cc8;
  --placeholderColor: #c7c7ca;
  --multiClearBG: #999999;
  --disabledBackground: #fbfbfb;
  --disabledBorderColor: #c7c7ca;
  --disabledColor: #000;
}</style>
