<script>
    import Lottie from 'components/Lottie';

    export let title = null;
    export let subtitle = null;
    export let animationPath = 'lottie/loading-circle.json';
    export let animationLoop = true;
</script>

<div class="loader animate__animated animate__fadeIn">
    <div class="loader__icon animate__animated animate__zoomIn">
        <Lottie jsonPath={animationPath} loop={animationLoop} />
    </div>
    {#if title}
        <div class="loader__title">{title}</div>
    {/if}
    {#if subtitle}
        <div class="loader__subtitle">{subtitle}</div>
    {/if}
</div>

<style lang="scss">.loader {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.loader__icon {
  width: 70px;
  height: 70px;
}
.loader__title {
  margin-top: 1.25em;
  font-size: 1.25em;
  font-weight: 500;
  color: #47474b;
  text-align: center;
  animation-iteration-count: infinite;
}
.loader__subtitle {
  margin-top: 0.625em;
  font-size: 1.125em;
  line-height: 1.2;
  color: #c7c7ca;
  text-align: center;
}</style>
