<script>
    import { onMount } from 'svelte';
    import Select from 'svelte-select';

    import SHARED_CONFIG from 'configs/shared';
    import REGISTER_CONFIG from 'configs/register';

    import authStore from 'stores/auth';
    import loansStore from 'stores/loans';
    import registerStore from 'stores/register';

    import { createLoan } from 'services/infinity';

    import PageOverlay from 'components/PageOverlay';
    import LoanCalculator from 'components/LoanCalculator';

    export let trigger = false;
    export let onClose = () => {};
    export let onSubmit = () => {};

    const countryOptions = REGISTER_CONFIG.OPTIONS.COUNTRY;
    let country = countryOptions[0];
    let activeLoan;
    let loan = {};

    onMount(() => {
        activeLoan = getActiveLoan();
    });

    $: {
        if (trigger) {
            activeLoan = getActiveLoan();
        }
    }

    async function _onSubmit() {
        console.debug('Creating new loan ...');

        await createLoan({
            loanType: SHARED_CONFIG.LOAN_TYPES.PERSONAL,
            customerId: $authStore.customerId,
            countryKey: country.value,
            loanAmount: loan.amount,
            refinanceId: activeLoan ? activeLoan.id : null,
            platform: $registerStore.essentials.platform,
        });

        onSubmit();

        return Promise.resolve({});
    }

    function getActiveLoan() {
        return $loansStore.loans.active.length > 0 ? $loansStore.loans.active[0] : null;
    }
</script>

<PageOverlay
    title="Apply for Loan / Top-up"
    submitLabel="Apply for Loan"
    loadingLabel={'Applying for Loan ...'}
    {trigger}
    onSubmit={_onSubmit}
    {onClose}
>
    <div slot="content">
        <form class="form">
            <div class="form__control">
                <label class="form__label" for="country">Select your Country</label>
                <div class="form__select">
                    <Select
                        items={countryOptions}
                        isClearable={false}
                        listPlacement="bottom"
                        bind:value={country}
                        on:select={(event) => {
                            country = event.detail;
                        }}
                        placeholder="Country"
                        showChevron={true}
                    />
                </div>
            </div>
            <div class="form__control animate__animated animate__fadeIn">
                <LoanCalculator
                    country={SHARED_CONFIG.COUNTRIES[country.value]}
                    {activeLoan}
                    on:calculate={(event) => {
                        loan.amount = event.detail.borrowAmount;
                        loan.numPayments = event.detail.numPayments;
                        loan.reason = event.detail.loanReason;
                    }}
                />
            </div>
        </form>
    </div>
</PageOverlay>

<style lang="scss">.form__control {
  margin: 1.5625em 0;
}
.form__label {
  font-weight: 500;
}
.form__select {
  width: 100%;
  font-size: 0.875em;
  font-weight: 400;
  --inputPadding: 15px 20px;
  --border: 1px solid #c7c7ca;
  --borderFocusColor: #662cc8;
  --borderRadius: 8px;
  --itemHoverBG: #f4f4f4;
  --itemIsActiveBG: #662cc8;
  --multiItemActiveBG: #662cc8;
  --placeholderColor: #c7c7ca;
  --multiClearBG: #999999;
  --disabledBackground: #fbfbfb;
  --disabledBorderColor: #c7c7ca;
  --disabledColor: #000;
}</style>
