<script>
</script>

<footer class="footer">
    <div class="footer__legal">Copyright© {new Date().getYear() + 1900} Term Finance Ltd.</div>

    <div class="footer__logos">
        <img src="images/nav_logo@2x.png" alt="logo" class="footer__logo" height="10" />
        <!-- <a href="https://fastcashcaribbean.com/" target="_blank">
            <img src="images/footer_logo_fastcash.png" alt="logo" class="footer__logo" />
        </a> -->
    </div>
</footer>

<style lang="scss">.footer {
  position: relative;
  overflow: hidden;
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.625em 0.625em;
  background: #002141;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;
}
.footer__logos {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -moz-column-gap: 0.625em;
       column-gap: 0.625em;
  width: 100%;
}
.footer__logo {
  cursor: pointer;
  margin-bottom: 0.3125em;
  max-width: none;
  height: 20px;
  opacity: 0.5;
}
.footer__legal {
  width: 100%;
  margin-bottom: 0.625em;
  font-size: 0.75em;
  text-transform: uppercase;
  color: #000;
  opacity: 0.4;
}
@media (min-width: 650px) {
  .footer {
    padding: 1.5625em 1.5625em;
    flex-flow: row nowrap;
  }
  .footer__logos {
    justify-content: flex-end;
    width: 50%;
  }
  .footer__logo {
    height: 20px;
    opacity: 0.5;
  }
  .footer__legal {
    width: 50%;
    margin-bottom: 0;
    text-align: left;
  }
}</style>
