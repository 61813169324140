<script>
    import { getContext } from 'svelte';
    import Button from 'components/Button';

    export let onClose = () => {};

    const { close } = getContext('simple-modal');

    function handleClose() {
        onClose();
        close();
    }
</script>

<div class="modal">
    <div class="modal-content">
        <h2 class="modal-content__title">Contact us</h2>
        <div class="modal__desc">
            <p>Axcel Finance is a web-based lender operating across the Caribbean.</p>
            <div class="contacts">
                <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Antigua</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 268-562-8222</div>
                    <a href="mailto:antigua@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> antigua@axcelfinance.com</a
                    >
                </div>
                <!-- <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Barbados</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 246-228-8222</div>
                    <a href="mailto:barbados@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> barbados@axcelfinance.com</a
                    >
                </div> -->
                <div class="contacts__contact">
                    <div class="contacts__name">Contact us in Grenada</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 473-435-8222</div>
                    <a href="mailto:grenada@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> grenada@axcelfinance.com</a
                    >
                </div>
                <!-- <div class="contacts__contact">
                    <div class="contacts__name">Contact us in St. Lucia</div>
                    <div class="contacts__number"><i class="fa fa-normal fa-phone"></i> 758-450-8222</div>
                    <a href="mailto:slu@axcelfinance.com" class="contacts__number"
                        ><i class="fa fa-normal fa-envelope"></i> slu@axcelfinance.com</a
                    >
                </div> -->
            </div>
        </div>
    </div>
    <div class="actions">
        <Button primary small onClick={handleClose}>OK</Button>
    </div>
</div>

<style lang="scss">.modal-content {
  margin-bottom: 1.875em;
  padding: 1.875em 1.875em 0 1.875em;
}
.modal-content__title {
  margin-bottom: 1.5625em;
  font-size: 1.25em;
  font-weight: 700;
  color: #d83e33;
  line-height: 1.25;
}
.modal-content :global(p) {
  margin-bottom: 1.5625em;
}

.contacts {
  display: block;
  justify-content: flex-start;
  grid-template-columns: 1fr 1fr;
  flex-flow: row wrap;
  align-items: center;
  margin-top: 1.875em;
}
@media (min-width: 1050px) {
  .contacts {
    display: grid;
  }
}
.contacts__contact {
  width: 100%;
  margin: 1.25em 0;
  font-size: 1em;
  display: flex;
  flex-flow: column nowrap;
  align-items: start;
  justify-content: start;
  row-gap: 5px;
}
.contacts__name {
  margin-bottom: 0.625em;
  color: #000;
}
.contacts__number {
  display: flex;
  justify-content: start;
  align-items: center;
  -moz-column-gap: 5px;
       column-gap: 5px;
  flex-wrap: nowrap;
  margin-bottom: 0.3125em;
  font-weight: 700;
  color: #d83e33;
}

.actions {
  padding: 0.625em 1.5625em;
  border-radius: 0 0 1.5625em 1.5625em;
  background: #fbfbfb;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
}</style>
