<script>
    export let iconUrl = null;
    export let primary = true;
    export let primaryInverse = false;
    export let secondary = false;
    export let secondaryInverse = false;
    export let transparent = false;
    export let cancel = false;
    export let fullWidth = false;
    export let disabled = false;
    export let small = false;
    export let medium = false;
    export let type = 'button';
    export let onClick = () => {};
</script>

<button
    class:primary
    class:primaryInverse
    class:secondary
    class:secondaryInverse
    class:transparent
    class:cancel
    class:fullWidth
    class:small
    class:medium
    on:click|preventDefault|stopPropagation={onClick}
    {type}
    {disabled}
>
    {#if iconUrl != null}<img alt="icon" class="icon" height="20" src={iconUrl} />{/if}
    <slot />
</button>

<style lang="scss">.primary {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 1.5625em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  cursor: pointer;
  background: #d83e33;
  color: #fff;
}
.primary:hover {
  background: #8b221a;
  box-shadow: 0px 0px 5px 6px rgba(216, 62, 51, 0.05);
  animation: pulse;
  animation-duration: 0.25s;
}
.primary:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.primaryInverse {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 1.5625em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  cursor: pointer;
  background: #fff;
  color: #d83e33;
}
.primaryInverse:hover {
  background: #cccccc;
  box-shadow: 0px 0px 5px 6px rgba(255, 255, 255, 0.05);
  animation: pulse;
  animation-duration: 0.25s;
}
.primaryInverse:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.secondary, .cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 1.5625em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  cursor: pointer;
  background: transparent;
  color: #d83e33;
  border: 1px solid #d83e33;
  box-shadow: none;
}
.secondary:hover, .cancel:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
  animation: pulse;
  animation-duration: 0.25s;
}
.secondary:disabled, .cancel:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.secondary:hover, .cancel:hover {
  box-shadow: none;
  background: #d83e33;
  color: #fff;
}

.secondaryInverse {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 1.5625em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  cursor: pointer;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
}
.secondaryInverse:hover {
  background: rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 5px 6px rgba(0, 0, 0, 0.05);
  animation: pulse;
  animation-duration: 0.25s;
}
.secondaryInverse:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.transparent {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 1px solid transparent;
  border-radius: 1.5625em;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1.25em 1.875em;
  transition: all 0.5s cubic-bezier(0.25, 0.8, 0.25, 1);
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  text-decoration: none;
  white-space: nowrap;
  letter-spacing: 0.2px;
  cursor: pointer;
  background: #d83e33;
  background: transparent;
  color: #fff;
}
.transparent:hover {
  background: #8b221a;
  box-shadow: 0px 0px 5px 6px rgba(216, 62, 51, 0.05);
  animation: pulse;
  animation-duration: 0.25s;
}
.transparent:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.transparent:hover {
  background: transparent;
  box-shadow: none;
}

.cancel {
  background: transparent;
  color: #d83e33;
  border-color: #d83e33;
}
.cancel:hover {
  border-color: #b52c23;
}

.fullWidth {
  width: 100%;
}

.small {
  font-size: 0.8125em;
}

.medium {
  font-size: 1em;
}

img {
  display: none;
  margin-right: 10px;
  vertical-align: middle;
}
@media (min-width: 650px) {
  img {
    display: inline-block;
  }
}</style>
