<script>
    import { onMount } from 'svelte';
    import { createEventDispatcher } from 'svelte';

    import SHARED_CONFIG from 'configs/shared';
    import REGISTER_CONFIG from 'configs/register';

    import registerStore from 'stores/register';

    const dispatch = createEventDispatcher();

    export let step = REGISTER_CONFIG.STEPS.ESSENTIALS;

    let completionStatus = {};

    $: {
        switch (step.key) {
            case REGISTER_CONFIG.STEPS.ESSENTIALS.key:
                break;
            case REGISTER_CONFIG.STEPS.LOAN.key:
                completionStatus[REGISTER_CONFIG.STEPS.ESSENTIALS.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.INSURANCE.key:
                completionStatus[REGISTER_CONFIG.STEPS.LOAN.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.DETAILS.key:
                completionStatus[REGISTER_CONFIG.STEPS.LOAN.key] = true;
                completionStatus[REGISTER_CONFIG.STEPS.INSURANCE.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.FINANCIALS.key:
                completionStatus[REGISTER_CONFIG.STEPS.DETAILS.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.EMPLOYER.key:
                completionStatus[REGISTER_CONFIG.STEPS.FINANCIALS.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.BUSINESS.key:
                completionStatus[REGISTER_CONFIG.STEPS.FINANCIALS.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.CAR.key:
                completionStatus[REGISTER_CONFIG.STEPS.EMPLOYER.key] = true;
                completionStatus[REGISTER_CONFIG.STEPS.BUSINESS.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.CREDENTIALS.key:
                completionStatus[REGISTER_CONFIG.STEPS.CAR.key] = true;
                completionStatus[REGISTER_CONFIG.STEPS.BUSINESS.key] = true;
                completionStatus[REGISTER_CONFIG.STEPS.EMPLOYER.key] = true;
                break;
            case REGISTER_CONFIG.STEPS.REVIEW.key:
                completionStatus[REGISTER_CONFIG.STEPS.CREDENTIALS.key] = true;
                completionStatus[REGISTER_CONFIG.STEPS.REVIEW.key] = true;
                break;
            default:
                break;
        }
    }

    onMount(() => {});

    function handleJump(targetStep) {
        dispatch('jump', {
            currentStep: step,
            targetStep,
        });
    }
</script>

<div class="root">
    {#if !$registerStore.isComplete}
        <div class="status-bar">
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.ESSENTIALS.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.ESSENTIALS.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.ESSENTIALS)}
            >
                1
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.LOAN.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.LOAN.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.LOAN)}
            >
                2
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--disabled={true ||
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType !=
                        SHARED_CONFIG.LOAN_TYPES.PERSONAL ||
                    $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].country?.value != 'TT'}
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.INSURANCE.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.INSURANCE.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.INSURANCE)}
            >
                3
            </div>
            <div class="status-bar__divider"></div>
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.DETAILS.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.DETAILS.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.DETAILS)}
            >
                4
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.FINANCIALS.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.FINANCIALS.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.FINANCIALS)}
            >
                5
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.BUSINESS.num ||
                    step.num == REGISTER_CONFIG.STEPS.EMPLOYER.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.BUSINESS.key] == true ||
                    completionStatus[REGISTER_CONFIG.STEPS.EMPLOYER.key] == true}
                on:keydown
                on:click={() =>
                    handleJump(
                        $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType ==
                            SHARED_CONFIG.LOAN_TYPES.PERSONAL ||
                            $registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType ==
                                SHARED_CONFIG.LOAN_TYPES.CAR
                            ? REGISTER_CONFIG.STEPS.EMPLOYER
                            : REGISTER_CONFIG.STEPS.BUSINESS,
                    )}
            >
                6
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--disabled={$registerStore[REGISTER_CONFIG.STEPS.ESSENTIALS.key].loanType !=
                    SHARED_CONFIG.LOAN_TYPES.CAR}
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.CAR.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.CAR.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.CAR)}
            >
                7
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.CREDENTIALS.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.CREDENTIALS.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.CREDENTIALS)}
            >
                8
            </div>
            <div class="status-bar__divider" />
            <div
                class="status-bar__status"
                class:status-bar__status--active={step.num == REGISTER_CONFIG.STEPS.REVIEW.num}
                class:status-bar__status--complete={completionStatus[REGISTER_CONFIG.STEPS.REVIEW.key] == true}
                on:keydown
                on:click={() => handleJump(REGISTER_CONFIG.STEPS.REVIEW)}
            >
                9
            </div>
        </div>
    {/if}
</div>

<style lang="scss" type="text/scss">.status-bar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.status-bar__status {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  background: white;
  border-radius: 50%;
  border: 1px solid white;
  color: #d83e33;
  font-weight: 900;
  font-size: 0.75em;
  transition: all 0.2s ease-in;
}
.status-bar__status--active {
  pointer-events: none !important;
  border: 2px solid #751d16;
  background: #fff;
  color: #d83e33;
}
.status-bar__status--complete {
  cursor: pointer;
  pointer-events: all !important;
  border: 2px solid #d83e33;
  background: #d83e33;
  color: #fff;
}
.status-bar__status--complete:hover {
  background: #e47b73 !important;
}
.status-bar__status--disabled {
  cursor: not-allowed;
  pointer-events: none !important;
  opacity: 0.25 !important;
}
@media (min-width: 650px) {
  .status-bar__status {
    width: 35px;
    height: 35px;
    font-size: 0.625em;
  }
}
.status-bar__divider {
  width: 10px;
  padding: 0;
  margin: 0;
  height: 2px;
  background: #fff;
}
@media (min-width: 650px) {
  .status-bar__divider {
    width: 10px;
  }
}</style>
