<script>
</script>

<div class="container">
    <slot />
</div>

<style lang="scss">.container {
  margin: 0 auto;
  padding: 0 1.5625em;
}
@media (min-width: 650px) {
  .container {
    margin: 0 auto;
    padding: 0 1.5625em;
  }
}
@media (min-width: 1200px) {
  .container {
    margin: 0 auto;
    padding: 0 1.5625em;
  }
}</style>
