<script>
    import { onMount, onDestroy } from 'svelte';
    import Dropzone from 'svelte-file-dropzone';

    import DOCS_CONFIG from 'configs/docs';

    import authStore from 'stores/auth';
    import docsStore from 'stores/docs';
    import customerStore from 'stores/customer';

    import { createCustomerFile } from 'services/infinity';
    import utilService from 'services/util';

    import AppLayout from 'components/AppLayout';
    import Lottie from 'components/Lottie';
    import Loader from 'components/Loader';

    let isLoadingFiles = true;
    let isUploadingAdditionalFiles = false;
    let files = {
        required: {
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_IDENTITY.id]: {
                file: null,
                isLoading: false,
                isSME: false,
            },
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_ADDRESS.id]: {
                file: null,
                isLoading: false,
                isSME: false,
            },
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_PAYMENT.id]: {
                file: null,
                isLoading: false,
                isSME: false,
            },
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS.id]: {
                file: null,
                isLoading: false,
                isSME: true,
            },
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS_FINANCIALS.id]: {
                file: null,
                isLoading: false,
                isSME: true,
            },
            [DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS_PICTURES.id]: {
                file: null,
                isLoading: false,
                isSME: true,
            },
        },
        additional: [],
    };

    onMount(() => {
        setTimeout(() => {
            loadCustomerFiles();
        }, 1);
    });

    onDestroy(() => {});

    function loadCustomerFiles() {
        isLoadingFiles = true;

        docsStore
            .fetchFiles($authStore.customerId)
            .then(() => {
                // This seems over complicated but lets set up the UI model from
                // the store by looping through the files.
                $docsStore.files.forEach((file) => {
                    if (
                        file.type.id &&
                        [
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_IDENTITY.id,
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_ADDRESS.id,
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_PAYMENT.id,
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS.id,
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS_FINANCIALS.id,
                            DOCS_CONFIG.FILE_TYPES.PROOF_OF_BUSINESS_PICTURES.id,
                        ].includes(file.type.id)
                    ) {
                        if (!files.required[file.type.id].file) {
                            files.required[file.type.id].file = file;
                        } else {
                            files.additional.push(file);
                        }
                    } else {
                        files.additional.push(file);
                    }
                });
            })
            .finally(() => {
                isLoadingFiles = false;
            });
    }

    function downloadFile(file) {
        docsStore.fetchBytes($authStore.customerId, file.id).then(
            (bytes) => {
                const downloadLink = document.createElement('a');
                downloadLink.href = `data:${file.mimeType};base64,${bytes}`;
                downloadLink.download = file.name;
                downloadLink.click();
            },
            (error) => {
                console.error('Unable to download file -> ', error);
            },
        );
    }

    function handleFileSelect(event, fileTypeId) {
        let { acceptedFiles, fileRejections } = event.detail;

        if (fileTypeId) {
            files.required[fileTypeId].isLoading = true;
        } else {
            isUploadingAdditionalFiles = true;
        }

        acceptedFiles.forEach((file) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = function () {
                createCustomerFile({
                    customerId: $authStore.customerId,
                    fileTypeId: fileTypeId ? fileTypeId : 5,
                    filename: file.name,
                    mimeType: file.type,
                    bytes: utilService.getBase64StringFromDataURL(reader.result),
                })
                    .then((data) => {
                        console.debug('File Upload Status -> ', data.file);

                        if (fileTypeId) {
                            files.required[fileTypeId].file = data.file;
                        } else {
                            files.additional.unshift(data.file);
                            files.additional = files.additional;
                        }
                    })
                    .finally(() => {
                        if (fileTypeId) {
                            files.required[fileTypeId].isLoading = false;
                        } else {
                            isUploadingAdditionalFiles = false;
                        }
                    });
            };

            reader.onerror = function (error) {
                console.error('File Upload Error -> ', error);
                if (fileTypeId) {
                    files.required[fileTypeId].isLoading = false;
                } else {
                    isUploadingAdditionalFiles = false;
                }
            };
        });
    }

    function getFileTypeLabelFromId(fileTypeId) {
        let fileTypeKey = Object.keys(DOCS_CONFIG.FILE_TYPES).filter((key) => {
            let fileType = DOCS_CONFIG.FILE_TYPES[key];
            return fileType.id == fileTypeId;
        });

        return DOCS_CONFIG.FILE_TYPES[fileTypeKey].label;
    }
</script>

<div class="root">
    <AppLayout title="Documents" subtitle="View, upload and manage all of your personal documents">
        <div slot="page">
            <div class="docs">
                {#if isLoadingFiles}
                    <div class="docs__loader">
                        <Loader title="Loading Docs ..." subtitle="This might take a few seconds ..." />
                    </div>
                {:else}
                    <section class="section">
                        <div class="section__title">Required Documents</div>
                        <div class="section__content">
                            <div class="media-grid animate__animated animate__fadeIn">
                                {#each Object.keys(files.required) as requiredFileTypeId}
                                    {#if !files.required[requiredFileTypeId].isSME || (files.required[requiredFileTypeId].isSME && $customerStore.store && $customerStore.store.isSME)}
                                        <div class="media">
                                            <Dropzone
                                                on:drop={(e) => handleFileSelect(e, requiredFileTypeId)}
                                                disableDefaultStyles={true}
                                                multiple={false}
                                            >
                                                <div
                                                    class="media-thumbnail"
                                                    class:media-thumbnail__image--uploaded={files.required[
                                                        requiredFileTypeId
                                                    ].file}
                                                >
                                                    <div
                                                        class="media-thumbnail__image"
                                                        class:media-thumbnail__image--uploading={files.required[
                                                            requiredFileTypeId
                                                        ].isLoading}
                                                    >
                                                        {#if files.required[requiredFileTypeId].isLoading}
                                                            <Lottie jsonPath="/lottie/docs-upload.json" />
                                                        {:else if !files.required[requiredFileTypeId].file}
                                                            <Lottie jsonPath="/lottie/docs-missing.json" loop={false} />
                                                        {:else}
                                                            <Lottie
                                                                jsonPath="/lottie/docs-uploaded.json"
                                                                loop={false}
                                                            />
                                                        {/if}
                                                    </div>
                                                </div>
                                            </Dropzone>
                                            <div class="media__type">{getFileTypeLabelFromId(requiredFileTypeId)}</div>
                                            {#if files.required[requiredFileTypeId].file}
                                                <div class="media__date">
                                                    Uploaded on
                                                    <strong
                                                        >{files.required[requiredFileTypeId].file.dateCreated}</strong
                                                    >
                                                </div>
                                                <div
                                                    class="media__action media__action--success"
                                                    on:click={() =>
                                                        downloadFile(files.required[requiredFileTypeId].file)}
                                                >
                                                    Download File
                                                </div>
                                            {:else}
                                                <div class="media__date">Date uploaded: <strong>N/A</strong></div>
                                                <div class="media__action media__action--pending">Not uploaded</div>
                                            {/if}
                                        </div>
                                    {/if}
                                {/each}
                            </div>
                        </div>
                    </section>
                    <section class="section">
                        <div class="section__title">Additional Documents</div>
                        <div class="section__content">
                            <Dropzone
                                on:drop={(e) => handleFileSelect(e, null)}
                                disableDefaultStyles={true}
                                disabled={isUploadingAdditionalFiles}
                                multiple={true}
                            >
                                <div class="dropzone">
                                    {#if isUploadingAdditionalFiles}
                                        <div class="dropzone__loader">
                                            <Lottie jsonPath="/lottie/docs-upload-gray.json" />
                                        </div>
                                    {:else}
                                        <div>Click here (or drag and drop files) to upload additional documents</div>
                                    {/if}
                                </div>
                            </Dropzone>
                            <div class="media-grid animate__animated animate__fadeIn">
                                {#each files.additional as file}
                                    <div class="media animate__animated animate__fadeIn">
                                        <div class="media-thumbnail media-thumbnail--additional">
                                            <div class="media-thumbnail__image">
                                                <Lottie jsonPath="/lottie/docs-media.json" loop={false} />
                                            </div>
                                        </div>
                                        <div class="media__type">{file.name}</div>
                                        <div class="media__date">Uploaded on <strong>{file.dateCreated}</strong></div>
                                        <div
                                            class="media__action media__action--success"
                                            on:click={() => downloadFile(file)}
                                        >
                                            Download File
                                        </div>
                                    </div>
                                {/each}
                            </div>
                        </div>
                    </section>
                {/if}
            </div>
        </div>
    </AppLayout>
</div>

<style lang="scss" type="text/scss">.root {
  min-height: 100vh;
  background: rgb(230, 228, 254);
  background: linear-gradient(83deg, rgb(230, 228, 254) 0%, rgb(203, 197, 255) 100%);
}

.docs {
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;
  margin: 1.5625em 0;
  padding: 0 1.5625em;
  flex: 1;
}
.docs__loader {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section {
  margin: 1.5625em 0;
}
.section:first-child {
  margin-top: 0.625em;
}
.section__title {
  margin-bottom: 1.5625em;
  padding-bottom: 0.4375em;
  font-size: 1.125em;
  font-weight: 400;
  color: #d83e33;
  border-bottom: 1px solid #e1e1e3;
}

.media-grid {
  overflow: hidden;
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 0.625em;
}
@media (min-width: 650px) {
  .media-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.25em;
  }
}

.media {
  margin-right: 0.625em;
  margin-bottom: 0.625em;
}
.media__type {
  margin-bottom: 0.5em;
  font-size: 1em;
  font-weight: 500;
  color: #000;
}
.media__date {
  font-size: 0.875em;
  font-weight: 400;
  color: #9f9fa5;
  font-family: "Roboto", sans-serif;
}
.media__action {
  font-size: 0.875em;
  margin-top: 0.625em;
  color: #d83e33;
  font-weight: 700;
}
.media__action--success {
  cursor: pointer;
  color: #d83e33;
}
.media__action--success:hover {
  color: #cb3227;
}
.media__action--pending {
  color: #9f9fa5;
}
@media (min-width: 1050px) {
  .media {
    margin-right: 3.125em;
    margin-bottom: 1.5625em;
  }
  .media__type {
    font-size: 1em;
  }
}

.media-thumbnail {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.625em;
  width: 130px;
  height: 105px;
  background: rgba(0, 0, 0, 0.05);
  border: 2px dashed rgba(0, 0, 0, 0.075);
  border-radius: 0.625em;
  cursor: pointer;
}
.media-thumbnail--additional {
  cursor: default;
  background: #fff;
  width: 120px;
  height: 90px;
  border: 1px solid transparent;
}
.media-thumbnail--additional:hover {
  background: rgba(255, 255, 255, 0.75) !important;
  box-shadow: none !important;
}
.media-thumbnail:hover {
  background: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
}
.media-thumbnail__image {
  width: 75px;
}
.media-thumbnail__image--uploading {
  width: 150px;
}
@media (min-width: 1050px) {
  .media-thumbnail {
    width: 200px;
    height: 175px;
  }
}

.dropzone {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  text-align: center;
  width: 100%;
  height: 125px;
  padding: 0 1.5625em;
  margin-bottom: 3.125em;
  background: rgba(0, 0, 0, 0.05);
  font-size: 0.875em;
  line-height: 1.25;
  color: #9f9fa5;
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 0.625em;
  cursor: pointer;
}
.dropzone:hover {
  background: rgba(0, 0, 0, 0.075);
}
.dropzone__loader {
  display: flex;
  width: 150px;
  height: 150px;
  margin-bottom: 0.625em;
  opacity: 0.4;
}</style>
